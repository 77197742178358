import React, { useState, useEffect } from "react";

import carApi from "api/carApi";
import {
  Button,
  Image,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  Statistic,
  Radio,
  Modal,
  DatePicker,
  Divider,
} from "antd";
import { currencyFormat } from "components/utils/Utils";
import { Edit } from "@mui/icons-material";
import Loading from "components/Loading";
import moment from "moment";
import PreviewProductModal from "components/products/table_product_list/PreviewProductModal";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToken } from "components/store/tokenSlice";
import EditProductDetailModal from "components/products/table_product_list/EditProductDetailModal";
import EditRetailStatus from "./EditRetailStatus";

const { Countdown } = Statistic;
export default function TableRetailList() {
  const user = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataHolder, setDataHolder] = useState([]);
  const [product, setProduct] = useState({});
  const [auctionUserList, setAuctionUserList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAuctionListModal, setOpenAuctionListModal] = useState(false);
  const [openEditDataModal, setOpenEditDataModal] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const [openProductStatusModal, setOpenProductStatusModal] = useState(false);

  const columns = [
    {
      key: 1,
      // dataIndex: "image_path",
      title: "รูปภาพ",
      render: (rec) => {
        return (
          <Space direction="vertical" align="center">
            <Image width={200} src={rec.image_path} />
            <Typography>สัญญาเดิม : {rec.contract_no}</Typography>
            <Typography>ทะเบียน : {rec.register_no}</Typography>
          </Space>
        );
      },
    },

    {
      key: 2,
      title: "รายละเอียดสินค้า",
      render: (rec) => {
        let color;
        switch (rec.status_type_id) {
          case 1:
            color = "success";
            break;
          case 2:
            color = "processing";
            break;
          case 3:
            color = "error";
            break;
          case 4:
            color = "error";
            break;
          default:
            break;
        }
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Space>
              <p>สถานะ</p>
              <Tag color={color}>{rec.status}</Tag>
            </Space>
            <Link to={"/retails/" + rec.id}>
              รหัสสินค้า : {rec.product_code}
            </Link>
            <Typography style={{ color: "gray" }}>
              วันที่ลง {moment(rec.date).format("ll")}
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
              ({rec.year}) {rec.brand}
            </Typography>
            <Typography style={{ color: "gray" }}>{rec.model}</Typography>
            <Typography style={{ color: "gray" }}>
              เลขไมล์ {currencyFormat(rec.miles)} กม.
            </Typography>
            <Typography style={{ color: "gray" }}>{rec.chassis_no}</Typography>
            <Typography style={{ fontWeight: "bold", color: "green" }}>
              ราคาขาย {currencyFormat(rec.retail_price)}
            </Typography>
          </div>
        );
      },
    },
    {
      key: 3,
      title: "รายละเอียดเซลล์ผู้ขาย",
      render: (rec) => {
        if (rec.process_by_user_id) {
          return (
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Link to={"/users/" + rec.user_id}>
                {rec.user_name} {rec.user_lastname} ({rec.user_nickname})
              </Link>

              {/* <Typography>อายุ : {rec.user_age}</Typography> */}
              <Typography style={{ color: "gray" }}>
                ที่อยู่ : {rec.user_address}
              </Typography>
              <Typography style={{ color: "gray" }}>
                โทรศัพท์ : {rec.user_phone}
              </Typography>
            </div>
          );
        }
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <>
              <Typography style={{ fontWeight: "bold", color: "blue" }}>
                ยังไม่ขาย
              </Typography>
              {/* <Button type="primary">เปิดประมูล</Button> */}
            </>
          </div>
        );
      },
    },

    {
      key: 4,
      //   dataIndex: "status_type_id",
      title: "ตัวเลือก",
      render: (rec) => {
        return (
          <Space direction="vertical" align="center">
            <Button
              type="primary"
              onClick={() => {
                handleClickEditStatus(rec);
              }}
            >
              แก้ไขสถานะสินค้า
            </Button>
            <Button
              onClick={() => {
                handleClickEdit(rec);
              }}
            >
              แก้ไขข้อมูลสินค้า
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    fetchAllProduct();
  }, []);

  const fetchAllProduct = async () => {
    setLoading(true);
    await carApi
      .get("/salecar/admin/products", {
        params: { type: 2 },
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        const data = response.data;
        setData(data);
        setDataHolder(data);
        setLoading(false);
        // setImageList([{ url: response.data.image_path }]);
        // if (response.data.product_code) {
        //   loadImagesProduct(response.data.product_code);
        //   //   setImageShow(response.data.image_path);
        // } else {
        //   setOpenModal(true);
        // }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
    // console.log("Products", data);
  };

  const updateProductDetail = async (product) => {
    // console.log("Product", product);
    setLoading(true);
    await carApi
      .put(`/salecar/admin/products/${product.id}`, product, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        Modal.success({ content: "อัพเดตข้อมูลประมูลสำเร็จ", centered: true });
        fetchAllProduct();
      })
      .catch((error) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const deleteProduct = async (id) => {
    setLoading(true);
    await carApi
      .delete(`/salecar/admin/products/${id}`, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        Modal.success({
          content: "ลบรายการสินค้าสำเร็จ",
          centered: true,
        });
        fetchAllProduct();
      })
      .catch((error) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const updateProductStatus = async (data) => {
    setLoading(true);
    await carApi
      .put("/salecar/admin/products/" + data.id + "/status", data, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          Modal.success({ content: "อัพเดพข้อมูลแล้ว", centered: true });
          fetchAllProduct();
        } else {
          setLoading(false);
          Modal.error({
            title: "ผิดพลาด",
            content: "อัพเดตไม่สำเร็จ กรุณาตรวจสอบข้อมูล",
            centered: true,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const fetchProductListByDate = async (date) => {
    setLoading(true);
    await carApi
      .get("/salecar/admin/products/date/" + date, {
        params: { type: 2 },
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        const data = response.data;
        setData(data);
        setDataHolder(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const handleClickEdit = (data) => {
    console.log(data);
    setDataEdit(data);
    setOpenEditDataModal(true);
  };

  const handleSubmitEdit = (value) => {
    updateProductDetail(value);
  };

  const handleDeleteData = (value) => {
    deleteProduct(value);
  };

  const onChangeMonth = (date, dateString) => {
    if (dateString !== "") {
      fetchProductListByDate(dateString);
    }
  };

  const handleClickEditStatus = (data) => {
    console.log("EditData", data);
    setDataEdit(data);
    setOpenProductStatusModal(true);
  };

  const handleSubmit = (data) => {
    if (data.status_type_id === 1) {
      data.process_by_user_id = null;
    }
    updateProductStatus(data);
  };

  const handleSearchTypeStatus = (e) => {
    const term = e.target.value;
    if (term === 0) {
      setData(dataHolder);
    } else {
      const newData = dataHolder.filter((item) => {
        return item.status_type_id === term;
      });
      setData(newData);
    }
  };

  return (
    <>
      {/* {loading ? <Loading /> : null} */}
      <h3>รายการสินค้าขายปลีก</h3>
      <div
        style={{
          padding: 8,
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <Radio.Group
          defaultValue={0}
          buttonStyle="solid"
          onChange={handleSearchTypeStatus}
        >
          <Radio.Button value={0}>ทั้งหมด</Radio.Button>
          <Radio.Button value={1}>คงอยู่</Radio.Button>
          <Radio.Button value={2}>รอดำเนินการ</Radio.Button>
          <Radio.Button value={4}>ขายแล้ว</Radio.Button>
        </Radio.Group>
        <Space>
          <p>กรองตามเดือน</p>
          <DatePicker
            onChange={onChangeMonth}
            placeholder="เดือนที่ลงประกาศ"
            picker="month"
            style={{ width: 200 }}
          />
        </Space>
      </div>
      {/* <Divider /> */}
      {loading ? <Loading /> : <Table dataSource={data} columns={columns} />}

      <EditProductDetailModal
        open={openEditDataModal}
        data={dataEdit}
        onSubmit={handleSubmitEdit}
        onDelete={handleDeleteData}
        onClose={setOpenEditDataModal}
      />

      <EditRetailStatus
        open={openProductStatusModal}
        data={dataEdit}
        onSubmit={handleSubmit}
        onClose={setOpenProductStatusModal}
      />
    </>
  );
}
