import React, { useState, useEffect } from "react";

import carApi from "api/carApi";
import {
  Button,
  Image,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  Statistic,
  Radio,
  Modal,
  DatePicker,
  Divider,
} from "antd";
import { currencyFormat } from "components/utils/Utils";
import { Edit } from "@mui/icons-material";
import Loading from "components/Loading";
import moment from "moment";
import PreviewProductModal from "./PreviewProductModal";
import { Link, useNavigate } from "react-router-dom";
import AuctionUserListModal from "../auction/AuctionUserListModal";
import { useDispatch, useSelector } from "react-redux";
import { addToken } from "components/store/tokenSlice";
import EditProductDetailModal from "./EditProductDetailModal";

const { Countdown } = Statistic;

export default function TableProductList() {
  const user = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataHolder, setDataHolder] = useState([]);
  const [product, setProduct] = useState({});
  const [auctionUserList, setAuctionUserList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAuctionListModal, setOpenAuctionListModal] = useState(false);
  const [openEditDataModal, setOpenEditDataModal] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  //   const [dueDateTime, setDueDateTime] = useState("");
  //   const [checKAuctionStatus, setAuctionStatus] = useState(false);
  //   const [colorCooldown, setColorCooldown] = useState("green");

  const columns = [
    {
      key: 1,
      // dataIndex: "image_path",
      title: "รูปภาพ",
      render: (rec) => {
        return (
          <Space direction="vertical" align="center">
            <Image width={200} src={rec.image_path} />
            <Typography>สัญญาเดิม : {rec.contract_no}</Typography>
            <Typography>ทะเบียน : {rec.register_no}</Typography>
          </Space>
        );
      },
    },

    {
      key: 2,
      title: "รายละเอียดสินค้า",
      render: (rec) => {
        let color;
        switch (rec.status_type_id) {
          case 1:
            color = "success";
            break;
          case 2:
            color = "processing";
            break;
          case 3:
            color = "error";
            break;
          case 4:
            color = "error";
            break;
          default:
            break;
        }
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Space>
              <p>สถานะ</p>
              <Tag color={color}>{rec.status}</Tag>
            </Space>
            <Link to={"/products/" + rec.id}>
              รหัสสินค้า : {rec.product_code}
            </Link>
            <Typography style={{ color: "gray" }}>
              วันที่ลง {moment(rec.date).format("ll")}
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
              ({rec.year}) {rec.brand}
            </Typography>
            <Typography style={{ color: "gray" }}>{rec.model}</Typography>
            <Typography style={{ color: "gray" }}>
              เลขไมล์ {currencyFormat(rec.miles)} กม.
            </Typography>
            <Typography style={{ color: "gray" }}>{rec.chassis_no}</Typography>
            <Typography style={{ fontWeight: "bold" }}>
              ราคาเปิด {currencyFormat(rec.auction_price)}
            </Typography>
          </div>
        );
      },
    },
    {
      key: 3,
      title: "รายละเอียดการประมูล",
      render: (rec) => {
        let auctionStatus;
        let color = "green";
        const currentDateTime = new Date();
        const deadLine = Date.parse(rec.auction_due_datetime);
        const miliseconds = deadLine - currentDateTime;
        // console.log(rec.id, deadLine);
        if (deadLine > currentDateTime) {
          auctionStatus = 1;
          if (miliseconds <= 6000000) {
            color = "red";
          } else color = "green";
        } else if (isNaN(deadLine) || deadLine === null) {
          auctionStatus = 0;
        } else auctionStatus = 2;

        // setDueDateTime(deadLine);
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            {auctionStatus === 1 ? (
              <>
                <Countdown
                  title="ระยะเวลาปิดประมูล"
                  valueStyle={{
                    color: color,
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                  value={deadLine}
                  format="D วัน H ชั่วโมง m นาที s วินาที"
                />
                <Typography style={{ fontWeight: "bold" }}>
                  ยอดสูงสุดตอนนี้ : {currencyFormat(rec.auction_total)}
                </Typography>
              </>
            ) : auctionStatus === 2 ? (
              <>
                <Typography style={{ fontWeight: "bold", color: "red" }}>
                  ปิดประมูลแล้ว
                </Typography>
                <Typography style={{ fontWeight: "bold" }}>
                  ยอดปิดประมูล {currencyFormat(rec.auction_total)}
                </Typography>
              </>
            ) : (
              <>
                <Typography style={{ fontWeight: "bold" }}>
                  ยังไม่เริ่มประมูล
                </Typography>
                {/* <Button type="primary">เปิดประมูล</Button> */}
              </>
            )}

            {rec.process_by_user_id ? (
              <>
                <Link to={"/users/" + rec.user_id}>
                  จากผู้ใช้ :{" "}
                  {rec.user_car_tent_name ||
                    rec.user_name + " " + rec.user_lastname}
                </Link>
                <Link
                  style={{ marginTop: 16 }}
                  onClick={() => fetchAuctionUserList(rec.id)}
                >
                  ดูผู้ร่วมประมูลทั้งหมด
                </Link>
              </>
            ) : null}
          </div>
        );
      },
    },

    {
      key: 4,
      //   dataIndex: "status_type_id",
      title: "ตัวเลือก",
      render: (rec) => {
        return (
          <Space direction="vertical" align="center">
            <Button
              type="primary"
              onClick={() => {
                handleClickAuction(rec);
              }}
            >
              จัดการประมูล
            </Button>
            <Button
              onClick={() => {
                handleClickEdit(rec);
              }}
            >
              แก้ไขข้อมูลสินค้า
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleClickEdit = (data) => {
    // console.log(data);
    setDataEdit(data);
    setOpenEditDataModal(true);
  };

  // const loadImagesProduct = async (product_code) => {
  //   console.log("LoadImageProductCode", product_code);
  //   setLoading(true);
  //   await carApi
  //     .get("/salecar_files/list/" + product_code)
  //     .then((response) => {
  //       console.log(response.data);
  //       // setImageList(response.data);
  //       setLoading(false);
  //       setOpenModal(true);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err);
  //     });
  // };

  const fetchAuctionUserList = async (id) => {
    console.log(id);
    await carApi
      .get(`/salecar/admin/auction-log/product/${id}`, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setAuctionUserList(response.data);
        setOpenAuctionListModal(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const handleClickAuction = async (rec) => {
    // console.log(rec);
    setLoading(true);
    await carApi
      .get(`/salecar/products/${rec.id}`)
      .then((response) => {
        // console.log(response.data);
        setProduct(response.data);
        setOpenModal(true);
        // setImageList([{ url: response.data.image_path }]);
        // if (response.data.product_code) {
        //   loadImagesProduct(response.data.product_code);
        //   //   setImageShow(response.data.image_path);
        // } else {
        //   setOpenModal(true);
        // }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  useEffect(() => {
    fetchAllProduct();
  }, []);

  const fetchAllProduct = async () => {
    setLoading(true);
    await carApi
      .get("/salecar/admin/products", {
        params: { type: 1 },
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        const data = response.data;
        setData(data);
        setDataHolder(data);
        setLoading(false);
        // setImageList([{ url: response.data.image_path }]);
        // if (response.data.product_code) {
        //   loadImagesProduct(response.data.product_code);
        //   //   setImageShow(response.data.image_path);
        // } else {
        //   setOpenModal(true);
        // }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
    // console.log("Products", data);
  };

  const handleSearchTypeStatus = (e) => {
    const term = e.target.value;
    if (term === 0) {
      setData(dataHolder);
    } else {
      const newData = dataHolder.filter((item) => {
        return item.status_type_id === term;
      });
      setData(newData);
    }
  };

  const updateAuctionData = async (data) => {
    setLoading(true);
    await carApi
      .put(`/salecar/admin/auction-status/products/${data.id}`, data, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        // setLoading(false);
        // onClose(false);
        Modal.success({ content: "อัพเดตรายการประมูลสำเร็จ", centered: true });
        fetchAllProduct();
      })
      .catch((error) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const updateProductDetail = async (product) => {
    setLoading(true);
    await carApi
      .put(`/salecar/admin/products/${product.id}`, product, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        Modal.success({ content: "อัพเดตข้อมูลประมูลสำเร็จ", centered: true });
        fetchAllProduct();
      })
      .catch((error) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const deleteProduct = async (id) => {
    setLoading(true);
    await carApi
      .delete(`/salecar/admin/products/${id}`, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        Modal.success({
          content: "ลบรายการสินค้าสำเร็จ",
          centered: true,
        });
        fetchAllProduct();
      })
      .catch((error) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const fetchProductListByDate = async (date) => {
    setLoading(true);
    await carApi
      .get("/salecar/admin/products/date/" + date, {
        params: { type: 1 },
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        const data = response.data;
        setData(data);
        setDataHolder(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const handleSubmitEdit = (value) => {
    updateProductDetail(value);
  };

  const handleDeleteData = (value) => {
    deleteProduct(value);
  };

  const onChangeMonth = (date, dateString) => {
    if (dateString !== "") {
      fetchProductListByDate(dateString);
    }
  };

  return (
    <>
      {/* {loading ? <Loading /> : null} */}
      <h3>รายการสินค้าประมูล</h3>
      {/* <div
        style={{
          padding: 8,
          justifyContent: "right",
          display: "flex",
        }}
      >
        <Radio.Group
          defaultValue={0}
          buttonStyle="solid"
          onChange={handleSearchTypeStatus}
        >
          <Radio.Button value={0}>ทั้งหมด</Radio.Button>
          <Radio.Button value={1}>คงอยู่</Radio.Button>
          <Radio.Button value={2}>รอดำเนินการ</Radio.Button>
          <Radio.Button value={3}>ขายแล้ว</Radio.Button>
        </Radio.Group>
      </div> */}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Space>
          <p>กรองตามเดือน</p>
          <DatePicker
            onChange={onChangeMonth}
            placeholder="เดือนที่ลงประกาศ"
            picker="month"
            style={{ width: 200 }}
          />
        </Space>
      </div>
      {/* <Divider /> */}
      {loading ? <Loading /> : <Table dataSource={data} columns={columns} />}

      <PreviewProductModal
        open={openModal}
        data={product}
        onSubmit={updateAuctionData}
        onClose={setOpenModal}
      />
      <AuctionUserListModal
        open={openAuctionListModal}
        data={auctionUserList}
        // onSubmit={updateAuctionData}
        onClose={setOpenAuctionListModal}
      />
      <EditProductDetailModal
        open={openEditDataModal}
        data={dataEdit}
        onSubmit={handleSubmitEdit}
        onDelete={handleDeleteData}
        onClose={setOpenEditDataModal}
      />
    </>
  );
}
