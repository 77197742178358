import React, { useEffect, useState } from "react";
// import { styled } from "@mui/material/styles";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Link,
} from "@mui/material";
import { Divider, Statistic } from "antd";
import { Link as RouterLink } from "react-router-dom";

import { currencyFormat } from "components/utils/Utils";
import moment from "moment";
import { useSelector } from "react-redux";

export default function RetailItem({ data }) {
  const user = useSelector((state) => state.token);
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Link
        to={"/retails/" + data.id}
        color="inherit"
        component={RouterLink}
        underline="none"
      >
        <Card>
          <CardMedia
            component="img"
            // height="200"
            image={data.image_path}
            alt="Paella dish"
          />
          <CardContent>
            {/* <Divider /> */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  {data.year} {data.brand}
                </Typography>
                <Typography variant="body2">{data.model}</Typography>
                <Typography
                  style={{ fontSize: 12, color: "gray" }}
                  variant="body2"
                >
                  {currencyFormat(data.miles)} กม. | {data.gear_type}
                </Typography>
                {/* <Typography style={{ fontWeight: "bold" }} variant="body2">
                  ราคาขาย
                </Typography> */}
                <Typography style={{ fontSize: 15 }} color="green">
                  {currencyFormat(data.retail_price)} บาท
                </Typography>
                {data.promotion && (
                  <Typography style={{ fontSize: 12 }} color="red">
                    มีข้อเสนอพิเศษ
                  </Typography>
                )}
              </div>
              <div>
                {/* <IconButton aria-label="add to favorites">
                  <FavoriteIcon />
                </IconButton> */}
              </div>
            </div>
            {/* <Divider /> */}
            {/* <div>
                  <Typography style={{ fontWeight: "bold" }} variant="body2">
                    ยอดประมูลล่าสุด
                  </Typography>
                  <Typography variant="body1" color="red">
                    {data.auction_total
                      ? currencyFormat(data.auction_total) + " บาท"
                      : "ยังไม่มี"}
                  </Typography>
                  <Typography style={{ color: "gray" }} variant="subtitle2">
                    โดยคุณ {data.user_userid ? censorName(data.user_name) : "-"}
                  </Typography>
                </div> */}
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}
