import { DatePicker, Divider, Form, Modal, Radio, Select } from "antd";
import carApi from "api/carApi";
import { addToken } from "components/store/tokenSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function EditRetailStatus({ open, data, onClose, onSubmit }) {
  const user = useSelector((state) => state.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [result, setResult] = useState(null);
  const [users, setUsers] = useState([]);
  const [defaultUser, setDefaultUser] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [selectedUserRoleId, setSelectedUserRoleId] = useState(4);
  const [soldDate, setSoldDate] = useState(null);

  useEffect(() => {
    // console.log(data);
    form.resetFields();
    setResult(data);
    fetchAllUser();
    if (data.status_type_id === 3 || data.status_type_id === 4) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    return () => {
      setUsers([]);
      setResult(null);
      setDefaultUser({});
      setDisabled(false);
    };
  }, [data]);

  useEffect(() => {
    fetchAllUser();
  }, [selectedUserRoleId]);

  const fetchAllUser = async () => {
    await carApi
      .get("/salecar/admin/users/role/" + selectedUserRoleId, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        const data = response.data;
        setUsers(data);
      })
      .catch((error) => {
        // setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleChange = (e) => {
    if (e.target.value === 3) {
      setSelectedUserRoleId(3);
      setDisabled(false);
    } else if (e.target.value === 4) {
      setSelectedUserRoleId(2);
      setDisabled(false);
    } else {
      setSelectedUserRoleId(null);
      setSoldDate(null);
      setDisabled(true);
    }
    setResult({ ...result, status_type_id: e.target.value });
  };

  const handleSelect = (e) => {
    // console.log("handleSelect", e.label);
    setResult({ ...result, process_by_user_id: e.label });
  };

  const handleSubmit = () => {
    if (selectedUserRoleId === 3 || selectedUserRoleId === 4) {
      if (soldDate && result.process_by_user_id) {
        const dataSubmit = {
          ...result,
          sold_out_date: soldDate,
        };
        onSubmit(dataSubmit);
        onClose(false);
      } else {
        alert("กรุณาเลือกวันที่ขาย และผู้ซื้อให้ครบ");
      }
    } else {
      const dataSubmit = {
        ...result,
        process_by_user_id: null,
        sold_out_date: null,
      };
      onSubmit(dataSubmit);
      onClose(false);
    }
  };

  const checkCurrentUser = () => {
    const current = users.find((user) => user.id === data.user_id);
    setDefaultUser(current);
    console.log("checkCurrentUser", current);
  };

  const handelDateChange = (date, dateString) => {
    console.log("handelDateChange", date, dateString);
    setSoldDate(dateString);
  };

  useEffect(() => {
    checkCurrentUser();
  }, [users]);

  if (result && open && users) {
    return (
      <Modal
        title={
          "สถานะสินค้า : " +
          result.brand +
          "  " +
          result.model +
          "  " +
          result.year
        }
        open={open}
        onOk={handleSubmit}
        onCancel={handleClose}
      >
        <Divider />
        <Radio.Group
          direction="vertical"
          style={{ width: 500 }}
          defaultValue={data.status_type_id}
          onChange={handleChange}
        >
          <Radio style={{ width: "100%" }} value={1}>
            คงอยู่
          </Radio>
          <Radio style={{ width: "100%" }} value={2}>
            รอดำเนินการ (อยู่ระหว่างซื้อขาย)
          </Radio>
          <Radio style={{ width: "100%" }} value={3}>
            ขายแล้ว (แบบประมูล)
          </Radio>
          <Radio style={{ width: "100%" }} value={4}>
            ขายแล้ว (แบบขายปลีก)
          </Radio>
          <Divider />
          <Form
            disabled={disabled}
            form={form}
            name="basic"
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 18,
            }}
            autoComplete="off"
          >
            <Form.Item
              label="วันที่ขาย"
              name="date"
              rules={[
                {
                  required: true,
                  message: "กำหนดวันที่สิ้นสุด",
                },
              ]}
            >
              <DatePicker onChange={handelDateChange} />
            </Form.Item>
            <Form.Item
              label="ชื่อผู้ซื้อ"
              name="process_by_user_id"
              rules={[
                {
                  required: true,
                  message: "กำหนดวันที่สิ้นสุด",
                },
              ]}
            >
              <Select
                onChange={(v, e) => handleSelect(e)}
                //   disabled={value === 1 ? false : true}
                defaultValue={defaultUser?.car_tent_name}
              >
                {users.map((user) => {
                  return (
                    <Select.Option
                      key={user.id}
                      value={
                        user.car_tent_name
                          ? user.car_tent_name
                          : user.firstname +
                            " " +
                            user.lastname +
                            " (" +
                            user.nickname +
                            ")"
                      }
                      label={user.id}
                    />
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Radio.Group>
      </Modal>
    );
  }
}
